import { Price } from '@faststore/ui'
import React, { useEffect, useState } from 'react'
import { useCart } from 'src/sdk/cart/useCart'
// import { updateOrderForm } from 'src/sdk/cart/useCheckoutButton'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import { useSession } from '@faststore/sdk'
import { useApplicationInsightsAPI } from 'src/sdk/insights/useApplicationInsightsAPI'
import { useStoreAPI } from 'src/sdk/store/useStoreAPI'
import { PageTypes } from 'src/typings/pageTypes'
import * as storeConfig from '../../../../store.config'
import ClerkioShelf from '../Clerkio/ClerkioShelf'
import {
  getSessionFavouriteStore,
  getSessionFavouriteStorePPData,
} from '../Store/utilities'
// import { getSessionIsVet } from '../UserAccount/utilities'
import AddedToCartMessage from './AddedToCartMessage'
import AddToCartAlertLoyaltyPointsPDP from './AddToCartAlertLoyaltyPointsPDP'
import Loading from './Loading'
import type { ExtendedProductDetails, FixedPrice, PriceInfo } from './typings'
import { getFixedPricesToShow } from './utilities'
import { notifyUpdate } from 'src/components/cart/AbandonedCartEventHandler/utils'
import { CartIcon } from 'src/components/icons/CartIcon'

type AddToCartAlertProp = {
  product: ExtendedProductDetails
  priceInfo: PriceInfo
  fixedPrices: Array<FixedPrice | null> | null | undefined
  quantity: number
  openAddToCart: boolean
  handleOpenCloseAddToCart: (x: boolean) => void
  setIsSubscription: React.Dispatch<React.SetStateAction<boolean>>
  isSubscription: boolean
  setIsAddedFromSticky: React.Dispatch<React.SetStateAction<boolean>>
  isAddedFromSticky: boolean
  loyaltyMultiplier: number
}

const { checkoutUrl } = storeConfig

function AddToCartAlertPDP({
  product,
  priceInfo,
  fixedPrices,
  quantity,
  openAddToCart,
  handleOpenCloseAddToCart,
  setIsSubscription,
  isSubscription,
  setIsAddedFromSticky,
  isAddedFromSticky,
  loyaltyMultiplier,
}: AddToCartAlertProp) {
  const CLERKIO_DATA_TEMPLATE = '@pdp-addtocart-modal'
  const [show, setShowMessage] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [isPending, setIsPending] = useState<boolean>(false)
  const { setShippingData } = useStoreAPI()
  const { person } = useSession()
  const cart = useCart()
  const { trackCustomEvent } = useApplicationInsightsAPI()
  const handleCloseModalClick = () => {
    handleOpenCloseAddToCart(false)
    setIsSubscription(false)
    setIsAddedFromSticky(false)
    setShowModal(false)
  }

  const handleGoToCart = async () => {
    setIsAddedFromSticky(false)
    setIsSubscription(false)
    setIsPending(true)

    // const isVet = getSessionIsVet()
    // if (!isVet) await updateOrderForm(cart.items, cart.id)

    const favouriteStore = getSessionFavouriteStore()
    const pickupPointData = getSessionFavouriteStorePPData()

    if (favouriteStore?.id && pickupPointData) {
      await setShippingData(cart.id, pickupPointData)
    }

    try {
      trackCustomEvent(
        {
          userId: person?.email,
          cartId: cart.id,
          pageType: PageTypes.PDP,
          actionTrigger: 'begin-checkout-button',
        },
        'begin-checkout'
      )
    } catch (e) {
      console.log(e)
    }

    // wait for the notification of cart update to SAS
    await notifyUpdate(person, cart.id)

    setIsPending(false)
    handleOpenCloseAddToCart(false)
    setShowModal(false)

    window.location.href = `${checkoutUrl}?orderFormId=${cart.id}#/cart`
  }

  const computedQuantity = isAddedFromSticky ? 1 : quantity
  const quantityToShow = product?.productSpecifications?.unitMultiplier
    ? computedQuantity * product?.productSpecifications?.unitMultiplier
    : computedQuantity

  const isSamePrice =
    priceInfo.verifiedListPriceToShow === priceInfo.verifiedPriceToShow

  const { price: fixedPrice } = getFixedPricesToShow(
    fixedPrices,
    quantity,
    priceInfo.verifiedUnitMultiplier,
    priceInfo.verifiedListPriceToShow,
    isSubscription
  )

  const price = isSubscription
    ? priceInfo.verifiedPriceToShow * 0.95
    : priceInfo.verifiedPriceToShow

  // const discount = isSubscription
  //   ? Math.round((1 - price / priceInfo.verifiedListPriceToShow) * 100)
  //   : priceInfo.discount

  useEffect(() => {
    setShowMessage(showModal)
  }, [showModal])

  const priceToUse = !fixedPrices?.length
    ? priceInfo.verifiedPriceToShow
    : fixedPrice
  const loyaltyPoints = isSubscription
    ? Math.trunc(priceToUse * quantity * 0.95) * loyaltyMultiplier
    : Math.trunc(priceToUse * quantity) * loyaltyMultiplier

  const productOutOfStockPrice = cart.items.reduce((acc: number, curr: any) => {
    let outOfStockPrice = 0
    const itemSku = curr?.itemOffered?.sku

    const itemVariantFiltered =
      curr?.itemOffered?.isVariantOf?.hasVariant.filter(
        (i: any) => i?.sku === itemSku
      )

    if (itemVariantFiltered && itemVariantFiltered[0]?.offers?.lowPrice <= 0) {
      outOfStockPrice += curr?.price * curr?.quantity
    }

    return acc + outOfStockPrice
  }, 0)
  const finalTotal = cart.total - productOutOfStockPrice
  const isUnderPrice = finalTotal < 8.99

  return (
    <>
      <Loading
        openModal={openAddToCart}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div
        id="product-added-to-cart"
        className={`modal-lightbox ${showModal ? 'open' : ''}`}
      >
        <div
          className="lightbox"
          style={{
            display: showModal ? 'block' : 'none',
          }}
        >
          <div className="mx-auto md:rounded-lg bg-white">
            <div id="prodotto-scelto-in-cart" className="tabs-panel active">
              <div className="py-6 relative">
                <div className="flex px-6 justify-between items-start gap-2">
                  <div>
                    <div className="flex items-center gap-2 mb-3">
                      {isSubscription ? (
                        <p className="text-lg md:text-xl font-normal">
                          Prodotto aggiunto al carrello con{' '}
                          <span className="text-orange-500">
                            Ordine Periodico
                          </span>
                        </p>
                      ) : (
                        <p className="text-lg md:text-xl font-normal">
                          Prodotto aggiunto al carrello
                        </p>
                      )}
                    </div>
                  </div>
                  <button
                    data-testid="close-add-to-cart-modal"
                    style={{ cursor: 'pointer', backgroundColor: 'white' }}
                    className="block"
                    data-close=""
                    onClick={handleCloseModalClick}
                  >
                    <span className="arca-ico-close text-2xl mt-1" />
                  </button>
                </div>
                <div className="mb-[134px] md:mb-0 h-screen md:h-[unset] overflow-auto">
                  <div className="px-6">
                    {openAddToCart && show && (
                      <AddedToCartMessage
                        setShowMessage={setShowMessage}
                        isUnderPrice={isUnderPrice}
                      />
                    )}
                    <div className="pt-4 pb-5 border-b-4 border-[#F2F4F5]">
                      <div className="flex gap-6 md:mb-8">
                        <div className="w-[110px] h-[110px] min-w-[110px] min-h-[110px] md:w-[124px] md:h-[124px] md:min-w-[124px] md:min-h-[124px] overflow-hidden">
                          <img
                            className="object-contain max-h-full mx-auto"
                            src={product?.image[0]?.url}
                            alt={product?.image[0]?.alternateName}
                          />
                        </div>
                        <div className="flex flex-col gap-1 md:gap-6">
                          <div>
                            <p className="uppercase text-[10px] md:text-xs text-[rgba(20,24,31,0.92)]">
                              {product.brand.name}
                            </p>
                            <p className="text-sm md:text-base font-normal">
                              {product.isVariantOf.name}
                            </p>
                          </div>
                          <div className="flex flex-col gap-0 md:gap-1 mt-6">
                            <div className="flex gap-1">
                              <p className="text-xs md:text-sm text-[rgba(20,24,31,0.92)]">
                                Quantità:
                              </p>
                              <p
                                className="text-xs md:text-sm font-normal"
                                data-value={quantityToShow}
                                data-testid="add-to-cart-quantity"
                              >
                                {quantityToShow}
                              </p>
                            </div>
                            <div className="flex gap-1">
                              <div className="flex items-center gap-1.5">
                                <Price
                                  value={
                                    !fixedPrices?.length
                                      ? priceInfo.verifiedPriceToShow
                                      : fixedPrice
                                  }
                                  formatter={useFormattedPrice}
                                  testId="add-to-cart-price"
                                  data-value={price}
                                  variant="spot"
                                  className="text-xs md:text-sm font-semibold"
                                />
                                {priceInfo.verifiedListPriceToShow &&
                                  !isSamePrice && (
                                    <Price
                                      // style={{ textDecoration: 'line-through' }}
                                      value={priceInfo.verifiedListPriceToShow}
                                      formatter={useFormattedPrice}
                                      testId="list-price"
                                      data-value={
                                        priceInfo.verifiedListPriceToShow
                                      }
                                      variant="listing"
                                      className="text-xs md:text-xs font-light"
                                    />
                                  )}
                                {/* {priceInfo.discount !== 0 && (
                                  <span className="block text-xs text-[#CE3535]">
                                    (-
                                    {fixedPrices && fixedPrices?.length > 0
                                      ? fixedDiscount
                                      : Math.round(discount)}
                                    %)
                                  </span>
                                )} */}
                              </div>
                            </div>
                            <div className="flex gap-1">
                              <AddToCartAlertLoyaltyPointsPDP
                                loyaltyPoints={loyaltyPoints}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="fixed md:relative bottom-0 left-0 w-full bg-white rounded-b-lg border-t-2 md:border-none border-[#F2F4F5] shadow-[0px_-2px_15px_rgba(0,0,0,0.15)] md:shadow-none flex flex-col md:flex-row-reverse gap-4 md:gap-[18px] px-6 pt-6 pb-8 md:p-0 z-[60]">
                        <div className="w-full md:max-w-1/2 md:mx-auto bg-transparent">
                          <button
                            className="btn btn-white w-full cursor-pointer"
                            data-close=""
                            onClick={handleCloseModalClick}
                          >
                            <span className="block">
                              Continua ad acquistare
                            </span>
                          </button>
                        </div>
                        {!isUnderPrice && (
                          <button
                            data-testid="modal-cart-button"
                            className={`w-full bg-transparent cursor-pointer group ${
                              isPending ? 'cursor-not-allowed' : ''
                            }`}
                            onClick={handleGoToCart}
                            disabled={isPending}
                          >
                            <div
                              className={`btn btn-primary w-full flex flex-nowrap gap-2 items-center justify-center`}
                            >
                              {isPending ? (
                                <div className="w-[22px] h-[22px] rounded-full border-4 border-gray-100 border-t-black group-hover:border-t-yellow animate-spin"></div>
                              ) : (
                                <>
                                  <CartIcon width={16} height={16} />
                                  <span className="block">Vai al carrello</span>
                                </>
                              )}
                            </div>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <ClerkioShelf
                    productLogic="products"
                    content={product.isVariantOf.productGroupID}
                    dataTemplate={CLERKIO_DATA_TEMPLATE}
                    isModal
                    showCarousel={showModal}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal-overlay ${
            showModal ? 'block opacity-100' : 'hidden opacity-0'
          }`}
          onClick={handleCloseModalClick}
        />
      </div>
    </>
  )
}

export default AddToCartAlertPDP
