import BadgesLoyaltyPointsPDP from './BadgesLoyaltyPointsPDP'

type BadgesProp = {
  isSubscriptionEligible: boolean
  badges: Array<{ id: string | null; name: string | null } | null> | null
  loyaltyPoints: number | undefined
}

function BadgesPDP({
  isSubscriptionEligible,
  badges,
  loyaltyPoints,
}: BadgesProp) {
  return (
    <div className="flex flex-wrap gap-2 text-xs font-[350]">
      {badges?.map((el: any, index: number) => {
        return (
          <div
            key={`badges${el.name}-${index}`}
            className="uppercase text-white py-1 px-2 flex items-center gap-1"
            style={
              index === 0
                ? { backgroundColor: '#ce3535' }
                : { backgroundColor: '#5068aa' }
            }
          >
            <span>{el.name}</span>
          </div>
        )
      })}
      {isSubscriptionEligible && (
        <div
          className="uppercase text-white py-1 px-2 flex items-center gap-1"
          style={{ backgroundColor: '#E27A13' }}
        >
          <div className="bg-white rounded-full h-[19px] w-[19px] flex items-center justify-center">
            <span className="arca-ico-autoship block text-lg !leading-[19px]">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </span>
          </div>
          <span className="whitespace-nowrap">ORDINE PERIODICO</span>
        </div>
      )}
      <BadgesLoyaltyPointsPDP loyaltyPoints={loyaltyPoints} />
    </div>
  )
}

export default BadgesPDP
